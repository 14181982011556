<template>
    <div class="equipment" v-if="dialogVisible">
        <div class="equipment-main">
            <section>
                <img class="title" src="@/assets/map/detail-title.png" alt="" />
                <i class="el-icon-circle-close icon" @click="dialogVisible = false"></i>
            </section>
            <section class="equipment-main-title">
                <div class="line-left"></div>
                <span class="line-tip">物资交付信息</span>
                <div class="line-right"></div>
            </section>
            <section class="equipment-main-table">
                <ul>
                    <li>
                        <div class="container">
                            <span class="form-title">设计图纸</span>
                            <div style="display: flex;flex-wrap:wrap; " v-if="detailData.imgDrawing.length">
                                <div class="content-bg" v-for="(item, index) in detailData.imgDrawing" :key="index"
                                    v-loading="loading">
                                    <img :src="item" preview="contract" class="preview-img" />
                                </div>
                            </div>
                            <p style="color: #fff" v-else>暂无图片</p>
                        </div>

                    </li>
                    <li>
                        <div class="container">
                            <span class="form-title">电子清单</span>
                            <div style="display: flex;flex-wrap:wrap;" v-if="detailData.imgInventory.length">
                                <div class="content-bg" v-for="(item, index) in detailData.imgInventory" :key="index"
                                    v-loading="loading">
                                    <img :src="item" preview="bill" class="preview-img" />
                                </div>
                            </div>
                            <p style="color: #fff" v-else>暂无图片</p>
                        </div>
                    </li>
                    <li>
                        <div class="container">
                            <span class="form-title">电子签章</span>
                            <div style="display: flex;flex-wrap:wrap;" v-if="detailData.imgSignature.length">
                                <div class="content-bg" v-for="(item, index) in detailData.imgSignature" :key="index"
                                    v-loading="loading">
                                    <img :src="item" preview="receipt" class="preview-img" />
                                </div>
                            </div>
                            <p style="color: #fff" v-else>暂无图片</p>
                        </div>
                    </li>

                </ul>
            </section>
            <section class="equipment-main-back">
                <span class="toBack mouseStyle" @click="backDetail">返回合同信息</span>
            </section>
        </div>
    </div>
</template>
<script>
import preview from 'vue-photo-preview';
import 'vue-photo-preview/dist/skin.css';
import Vue from 'vue';
var options = {
    fullscreenEl: false //关闭全屏按钮
};
Vue.use(preview, options);
import { contractInfo } from '../api';
export default {
    data() {
        return {
            contractOn: '',
            dialogVisible: false,
            loading: true,
            detailData: {
                imgDrawing: [],
                imgInventory: [],
                imgSignature: []
            }
        };
    },
    watch: {
        dialogVisible: function (val) {
            if (val) {
                this.loading = true;
                console.log(this.contractOn);
                // 初始化
                this.detailData = {
                    imgDrawing: [],
                    imgInventory: [],
                    imgSignature: []
                }

                this.contractEntityQuerys()
            }
        }
    },
    filters: {
        getStauts(val) {
            switch (val) {
                case 'NOTACTIVE':
                    return '未激活';
                case 'NORMAL':
                    return '已激活';
                case 'INVALID':
                    return '已到期';
            }
        }
    },
    methods: {

        async contractEntityQuerys() {
            const res = await contractInfo({ id: this.contractOn.id });
            console.log(res, 'contractInfo')
            if (res.data.imgDrawing == null) {
                this.detailData.imgDrawing = []
            } else {
                this.detailData.imgDrawing = res.data.imgDrawing.split(',').map(e => {
                    return `https://www.huaxucloud.com/jshERP-boot/systemConfig/static/${e}`
                })
            }


            // this.detailData.imgDrawing = [...this.detailData.imgDrawing, ...this.detailData.imgDrawing]
            // this.detailData.imgDrawing = [...this.detailData.imgDrawing, ...this.detailData.imgDrawing]
            // this.detailData.imgDrawing = [...this.detailData.imgDrawing, ...this.detailData.imgDrawing]
            // this.detailData.imgDrawing = [...this.detailData.imgDrawing, ...this.detailData.imgDrawing]
            // this.detailData.imgDrawing = [...this.detailData.imgDrawing, ...this.detailData.imgDrawing]

            if (res.data.imgInventory == null) {
                this.detailData.imgInventory = []
            } else {
                this.detailData.imgInventory = res.data.imgInventory.split(',').map(e => {
                    return `https://www.huaxucloud.com/jshERP-boot/systemConfig/static/${e}`
                })
            }
            if (res.data.imgSignature == null) {
                this.detailData.imgSignature = []
            } else {
                this.detailData.imgSignature = res.data.imgSignature.split(',').map(e => {
                    return `https://www.huaxucloud.com/jshERP-boot/systemConfig/static/${e}`
                })
            }
            setTimeout(() => {
                // 调用图片预览方法
                this.$previewRefresh();
            }, 100);
            setTimeout(() => {
                this.loading = false;
                console.log(this.detailData, 'this.detailData')
            }, 500);


        },

        backDetail() {
            this.$emit('backDetail');
        }
    }
};
</script>
<style lang="less" scoped>
* {
    box-sizing: border-box;
}

.equipment {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    padding-top: 100px;
    background: rgba(1, 3, 9, 0.7);
    font-size: 15px;

    &-main {
        padding: 0 72px;
        position: absolute;
        top: calc(50% - 370px);
        left: calc(50% - 531px);
        background: url('../../../assets/map/detail.png') no-repeat center/100%;
        z-index: 98;
        width: 1062px;
        height: 740px;

        .title {
            width: 244px;
            height: 50px;
            display: block;
            margin: -18px auto 0;
        }

        .icon {
            font-size: 36px;
            color: #34defc;
            position: absolute;
            top: -18px;
            right: 0;
        }

        &-title {
            height: 20px;
            font-size: 18px;
            color: #06d5f5;
            margin: 23px 0;
            display: flex;
            align-items: center;

            .line-left,
            .line-right {
                height: 2px;
                width: 100%;
                background-image: linear-gradient(to right, rgba(6, 172, 212, 1), rgba(6, 172, 212, 0));
            }

            .line-tip {
                width: 390px;
            }

            .line-right {
                background-image: linear-gradient(to left, rgba(6, 172, 212, 1), rgba(6, 172, 212, 0));
            }
        }

        &-search {
            display: flex;
            height: 40px;
            align-items: center;
            margin-bottom: 23px;

            .build {
                color: #fff;
            }

            .select {
                margin-right: 10px;

                & ::v-deep .el-input__inner {
                    background-color: transparent;
                    border-color: #0697c7;
                    color: #0697c7;
                }
            }

            .input {
                width: 200px;
                height: 100%;
                margin-right: 10px;

                & ::v-deep .el-input__inner {
                    color: #0697c7;
                    height: 100%;
                    border-radius: 50px;
                    background-color: transparent;
                    border-color: #0697c7;
                }
            }

            .btn {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                width: 97px;
                height: 100%;
                border-radius: 50px;
                border: 1px solid #0697c7;
            }
        }

        &-table {
            & ::v-deep .el-table {
                background-color: transparent;
            }

            & ::v-deep tr {
                background-color: transparent;
            }

            & ::v-deep th {
                color: #fff;
                background-color: rgba(0, 156, 255, 0.3);
            }

            & ::v-deep td {
                color: #06d5f5;
                background-color: rgba(0, 156, 255, 0.1);
            }

            & ::v-deep .el-table--border .el-table__cell {
                border-color: RGB(4, 36, 103);
                border-right: 1px solid #0454a6;
                border-bottom: 1px solid #093477;
            }

            & ::v-deep .el-table--border {
                border-color: transparent;
            }

            .el-table--border::after,
            .el-table--group::after {
                background-color: RGB(4, 36, 103);
            }

            .el-table--border::before,
            .el-table--group::before {
                background-color: RGB(4, 36, 103);
            }

            & ::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
                background-color: RGB(4, 36, 103);
            }
        }

        &-back {
            padding: 0 72px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            position: absolute;
            bottom: 70px;
            left: 0;

            & ::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
                color: #00ebff;
            }

            & ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
                background-color: #00ebff;
                color: #fff;
            }

            & ::v-deep .el-pagination.is-background .btn-next,
            .el-pagination.is-background .btn-prev,
            .el-pagination.is-background .el-pager li {
                background-color: #999eb9;
            }

            .toBack {
                width: 120px;
                height: 33px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50px;
                background-color: #01428b;
                color: #fff;
            }
        }
    }
}

li {
    display: flex;
    margin-bottom: 34px;
}

.container {
    // width: 50%;
    display: flex;
    align-items: center;
    min-height: 60px;
}

.form-title {
    color: #06d5f5;
    width: 120px;
    text-align: right;
    margin-right: 18px;
    flex:none
}

.content {
    color: #fff;
    height: 32px;
    display: flex;
    align-items: center;
    width: 282px;
    padding: 0 15px;
    border-radius: 50px;
    background-color: #192b87;
    color: #fff;
}

.content-bg {
    width: 85px;
    height: 60px;
    margin-right: 10px;
    border-radius: 5px;
    background-color: #bbb;
    margin-bottom: 6px;
}

.preview-img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
</style>